
  import {prepareImageUrl} from '@/helper/ImageHelper';

  export default {
    name: 'ArticleCard',
    filters: {
      truncate(string, value) {
        return string.length > value
          ? string.substring(0, value) + '…'
          : string;
      },
    },
    props: {
      article: {
        type: Object,
        default: () => {},
      },
      fillHeight: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    serverCacheKey: function (props) {
      // return a key based on the props. in this case, we're using the `id` prop:
      return 'article-card-' + props.article.id;
    },
    data() {
      return {
        imageUrl: process.env.NUXT_ENV_FALLBACK_CARD_IMAGE_STATIC,
        organizationScope: process.env.SCOPE_ORGANIZATION,
      };
    },
    created() {
      if (this.article.card_image) {
        this.imageUrl = prepareImageUrl(this.article.card_image.url);
      }
    },
  };
